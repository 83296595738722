// App.vue

<template>
  <div id="app">
    <!-- Header section -->
    <header class="bg-dark text-white text-center py-3">
      <h1 class="mb-0">team Aktienbuch</h1>
    </header>

    <!-- Main content section -->
    <main class="container mt-3">
      <!-- Chart component -->
      <!-- <div class="card mb-3">
        <div class="card-body">
          <CandleStick />
        </div>
      </div> -->

      <div class="card mb-3">
        <div class="card-body">
          <PriceTrendChart />
        </div>
      </div>

      <!-- KPI Boxes -->
      <div class="row mb-3">
        <div class="col-6 col-md-3">
          <div class="card">
            <div class="card-body">
              <!-- KPI content here -->
              ∑ {{ sumOfAnzahl }}
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="card">
            <div class="card-body">
              <!-- KPI content here -->
              ø {{ averageVerkauftZu.toFixed(2, 0) }} €
            </div>
          </div>
        </div>
        <div class="col-4 col-md-2">
          <div class="card">
            <div class="card-body">
              <!-- KPI content here -->
              <label>🆓 Frei:</label>
              {{ sumOfAnzahlFrei }}
            </div>
          </div>
        </div>
        <div class="col-4 col-md-2">
          <div class="card">
            <div class="card-body">
              <!-- KPI content here -->
              <label>🏷️ Abwicklung:</label>
              {{ sumOfinAbwicklung }}
            </div>
          </div>
        </div>

        <div class="col-4 col-md-2">
          <div class="card">
            <a
              href="https://www.team.de/se/fuer-aktionaere.html"
              target="_blank"
              class="btn btn-warning"
            >
              Jetzt Kaufen <i class="fas fa-shopping-cart"></i>
            </a>
          </div>
        </div>
      </div>
    </main>
    <!-- Footer section -->
    <footer class="bg-dark text-white text-center py-3">
      <p>Letzter Stand: {{ this.latest_date }}</p>
      <p class="mb-0">✌️</p>
    </footer>
  </div>
</template>

<script>
import ApiService from "./services/ApiService";
import PriceTrendChart from "./components/PriceTrendChart.vue";

export default {
  name: "App",
  components: {
    PriceTrendChart,
  },
  data: function () {
    return {
      current_data: [],
      latest_date: "lädt...",
      loading: true, // Initialize loading to true
    };
  },
  async mounted() {
    this.loading = true;
    let newData = await ApiService.newestData();
    newData = newData[0];
    this.current_data = newData["data"][0];
    this.latest_date = newData["date"];

    this.latest_date = new Date(this.latest_date).toLocaleDateString("de-DE", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  },
  methods: {
    calculateSumForStatus(status) {
      let sum = 0;
      for (const key in this.current_data) {
        if (Object.prototype.hasOwnProperty.call(this.current_data, key)) {
          const item = this.current_data[key];
          if (
            item["Status"] === status &&
            Object.prototype.hasOwnProperty.call(item, "Anzahl")
          ) {
            const anzahlValue = parseFloat(item["Anzahl"]);
            sum += isNaN(anzahlValue) ? 0 : anzahlValue;
          }
        }
      }
      return sum;
    },
  },
  computed: {
    sumOfAnzahl() {
      let sum = 0;
      for (const key in this.current_data) {
        if (Object.prototype.hasOwnProperty.call(this.current_data, key)) {
          const item = this.current_data[key];
          if (Object.prototype.hasOwnProperty.call(item, "Anzahl")) {
            const anzahlValue = parseFloat(item["Anzahl"]);
            sum += isNaN(anzahlValue) ? 0 : anzahlValue;
          }
        }
      }
      return sum;
    },

    averageVerkauftZu() {
      let count = 0;
      let sum = 0;
      for (const key in this.current_data) {
        if (Object.prototype.hasOwnProperty.call(this.current_data, key)) {
          const item = this.current_data[key];
          if (Object.prototype.hasOwnProperty.call(item, "verkauft zu")) {
            const verkauftZuValue = parseFloat(item["verkauft zu"]);
            if (!isNaN(verkauftZuValue)) {
              sum += verkauftZuValue;
              count++;
            }
          }
        }
      }
      return count === 0 ? 0 : sum / count;
    },
    sumOfAnzahlFrei() {
      return this.calculateSumForStatus("frei");
    },
    sumOfinAbwicklung() {
      // Replace "another-status" with the status you want to track
      return this.calculateSumForStatus("in Abwicklung");
    },
  },
};
</script>

<style>
/* Style the loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust the height as needed */
  font-size: 2rem;
}
</style>
