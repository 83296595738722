<template>
  <div>
    <!-- Show the loading spinner when loading is true -->
    <div v-if="loading" class="loading-spinner">
      Lädt...
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <!-- Show the chart when loading is false -->
    <div v-else>
      <apexchart :options="chartOptions" :series="chartData"></apexchart>
    </div>
  </div>
</template>

<script>
import ApiService from "./../services/ApiService";

function priceFormatter(val) {
  return val.toFixed(2) + " €";
}

export default {
  data() {
    return {
      chartData: [],
      chartOptions: {
        chart: {
          stacked: false,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (value, timestamp) {
              const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
              };
              return new Date(timestamp).toLocaleDateString("de-DE", options);
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [2],
          formatter: function (val) {
            return val.toFixed(2) + " €";
          },
        },
        stroke: {
          width: [5, 1, 5, 1],
        },

        fill: {
          opacity: [0.85, 0.25, 1, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },

        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            opposite: false,
            title: {
              text: "Anzahl",
            },
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
          },
          {
            axisTicks: {
              show: true,
            },
            opposite: true,
            title: {
              text: "Preis in €",
            },
            labels: {
              formatter: function (val) {
                return priceFormatter(val);
              },
            },
          },

          // values 3 & 4 are for the second y-axis
          {
            show: false,
            labels: {
              formatter: function (val) {
                return priceFormatter(val);
              },
            },
            axisTicks: {
              show: false,
            },
          },

          {
            show: false,
            labels: {
              formatter: function (val) {
                return priceFormatter(val);
              },
            },
            axisTicks: {
              show: false,
            },
          },
        ],
      },
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;

    let stockData = await ApiService.loadDayData();
    let seriesData = [];

    function formatDate(dateString) {
      // Extract year, month, and day as strings
      let year = dateString.substring(0, 2);
      let month = dateString.substring(2, 4);
      let day = dateString.substring(4, 6);

      // Construct a new date string in the format yyyy/mm/dd
      let formattedDate = "20" + year + "/" + month + "/" + day;

      return formattedDate;
    }

    let offersData = {
      name: "Alle angebotenen Aktienpakete",
      type: "column",
      data: [],
      yaxis: "y1",
    };
    let priceData = {
      name: "Durchschnittspreis",
      type: "line",
      data: [],
      yaxis: "y2",
    };

    let minPricesData = {
      name: "Mindestpreis",
      type: "area",
      data: [],
    };

    let maxPricesData = {
      name: "Höchstpreis",
      type: "line",
      data: [],
    };

    const forSaleData = {
      name: "Gesamtanzahl angebotene Aktien",
      type: "bar",
      data: [],
      color: "green",
    };

    const marketSize = {
      name: "Summe aller Aktien",
      type: "bar",
      data: [],
    };

    // Populate seriesData with the data you have
    for (let item of Object.keys(stockData)) {
      let obj = stockData[item];
      let date = new Date(formatDate(obj.Datum));

      priceData.data.push([date, obj["Durchschnittspreis"]]);
      minPricesData.data.push([date, obj["Mindestpreis"]]);
      maxPricesData.data.push([date, obj["Höchstpreis"]]);

      offersData.data.push([date, obj["Angebote"]]);

      // inProgressData.data.push([date, obj['In Abwicklung']]);
      // forSalePaketsData.data.push([date, obj['Freie Angebote']]);
      forSaleData.data.push([date, obj["Anzahl angebotene Aktien"]]);
      marketSize.data.push([date, obj["Gesamtwert"]]);
    }

    seriesData.push(offersData);
    seriesData.push(minPricesData);
    seriesData.push(priceData);
    seriesData.push(maxPricesData);

    this.chartData = seriesData;
    this.loading = false;
  },
};
</script>
