const API_URL =
  "https://p0e5kibbza.execute-api.eu-central-1.amazonaws.com/prod";
export default {
  async loadDayData() {
    const API_HEADERS = {
      "Content-Type": "application/json",
    };
    const requestOptions = {
      method: "GET",
      headers: API_HEADERS,
      redirect: "follow",
    };

    return fetch(API_URL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("API request failed");
        }
        return response.json();
      })
      .then((result) => {
        // return {
        //   statusCode: result.statusCode,
        //   body: result.body
        // };
        return JSON.parse(result.body);

        // let stockData=response['body']
      })
      .catch((error) => {
        console.log("APIService/API error:", error);
        console.log("Using local data");
      });
  },

  async newestData() {
    const API_HEADERS = {
      "Content-Type": "application/json",
    };
    const requestOptions = {
      method: "POST",
      headers: API_HEADERS,
    };
    return fetch(API_URL, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("API request failed");
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result['body'])
        console.log(result.body);
        return [{ data: JSON.parse(result["body"]), date: result["date"] }];
      })
      .catch((error) => {
        console.log("APIService/API error:", error);
      });
  },
};
