import Vue from "vue";
import App from "./App.vue";
import VueApexCharts from "vue-apexcharts";

// Import the Font Awesome CSS
import "@fortawesome/fontawesome-free/css/all.css";

// Import the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Import the Bootstrap JS (you can choose between 'bootstrap' or 'bootstrap.bundle')
import "bootstrap/dist/js/bootstrap.bundle.min.js";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
